
<template>
  <!-- :title="data.siteServiceTypeName" -->
  <div :id="chartId" class="board-card-chart" >
    <div
      class="main"
      v-loading="loading"
      :element-loading-text="$t('alarm.a0')"
    >
      <!-- <h3 class="data-title">{{ title }}</h3> -->
      <div class="data-chart" >

        <div :id="echartsId" :style="{ width: '100%', height: '100%' }"></div>
        <div class="c-title">{{ title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getEnergyDailyData,getVisitorReportData,getDoorCardData,getData,getVideoCardData,getDeviceData,getDeviceCardData,getDeviceStatusData} from "../../../api/board";
import { EleResize } from "../../../utils/esresize";
import moment from "moment";
const colors=['#7682CE','#d1b7dd','#86c9e3','#99d080','#607aca','#fc8958','#ef6b6b','#f7df1e'];
const colors3=['#73dfe2','#02e15c','#00c2ff','#7ddefd','#18cdd2','#7eeeab','#807cf4','#0db0f1'];
const colors2=['#03de5e','#18cdd2'];
export default {
  name: "electricityenergy",
  props: {
    token:{
      type:String,
    },
    data: {
      type: Object,
    },
    index:{
      type:Number,
      default:0
    }
  },
  data() {
    return {
      loading: false,
      option: {
        color: "#7682CE",
        backgroundColor:'rgba(12,90,150,0)',
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ",
        },
        legend: { },
        grid: {
          left: "0%",
          right: "0%",
          bottom: "0%",
          top: "18%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: [],
          axisTick: {
            alignWithLabel: true,
          },
          // axisLabel:{
          //     show:true,
          //     interval:0,
          // }
        },
        yAxis: {
          // name: "(KWh/月)",
          top: "5%",
          type: "value",
        },
        series: {
          name: "用电量(KWh/月)",
          type: "bar",
          data: [],
        },
      },
      roseType:"radius",
      option2: {
        color: colors,
        backgroundColor:'rgba(12,90,150,0)',
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            type: "pie",
            radius: "50%",
            data: [],
            roseType: undefined,
            animationType: "scale",
            animationEasing: "elasticOut",
            animationDelay: function (idx) {
              return Math.random() * 200;
            },
          },
        ],
      },
      option3: {
        color: colors2,
        backgroundColor:'rgba(12,90,150,0)',
        tooltip: {
          trigger: 'axis',
          formatter: function (params) {
              let name = params[0].name;
              let aname =  params[0].seriesName;
              let avalue =  params[0].value*1;
              let bname =  params[1].seriesName;
              let bvalue =  params[1].value*1;
              let arate = avalue/(avalue+bvalue) * 100;
              let brate = bvalue/(avalue+bvalue) * 100;
              return name + ' <br/>'+aname+' : '+avalue+' ('+arate.toFixed(2)+'%)'+ ' <br/>'+bname+' : '+bvalue+' ('+brate.toFixed(2)+'%)';
            },
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {textStyle: { fontSize: '1.4rem' }},
        grid: {
          left: '1%',
          right: '14%',
          bottom: '0%',
          top:'12%',
          containLabel: true
        },
        xAxis: [ { type: 'value',axisPointer:{show:false},axisLine:{show :true},  } ],
        yAxis: [],
        series: []
      },
      option4:{
        color: colors3,
        backgroundColor:'rgba(12,90,150,0)',
        tooltip: {
          trigger: 'item'
        },
        grid: {
          left: '0%',
          right: '0%',
          bottom: '0%',
          top:'0%',
          containLabel: true
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['30%', '60%'],
            avoidLabelOverlap: false,
            inverse: true,
            itemStyle: {
              borderRadius: 0,
              borderColor: '#030d1b',
              borderWidth: 1
            },
            label: {
              formatter: '{b|{d}%} \n {a|{b}}',
              // backgroundColor: '#F6F8FC',
              // borderColor: '#8C8D8E',
              // borderWidth: 1,
              // borderRadius: 4,
              rich: {
                a: {
                  color: '#e1deff',
                  fontSize: '1.2rem',
                  margin:'0.5rem',
                },
                b: {
                  color: '#8aaaff',
                  fontSize: '1.4rem',
                  fontWeight: 'bold',
                }
              }
            },
            
            labelLine: {
              show: true
            },
            data: [
             
            ]
          }
        ]
      },
      title:'',
      serie1: {
        name: this.$t("video.viddata065"),
        type: "pie",
        selectedMode: "single",
        radius: [0, "30%"],
        label: {
          position: "inner",
          fontSize: 10,
        },
        labelLine: {
          show: false,
        },
        data: [],
      },
      serie2: {
        name: this.$t("video.viddata065"),
        type: "pie",
        radius: ["45%", "60%"],
        data: [],
      },

    };
  },
  computed: {
    echartsId() {
      return "board-chart-" +  Math.floor((Math.random() * 10000) + 1);
    },
    chartId(){
      return "board-card-" +  Math.floor((Math.random() * 10000) + 1);
    },
  },
  watch:{
    // "$parent.dialogVisible":{
    //   handler(val){
    //       if(val){      
    //       }
    //   },
    //   deep:true,
    //   immediate:true,
    // },
    
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData(){
      const {siteId,siteServiceTypeID}  = this.data;
      if(siteServiceTypeID===8 || siteServiceTypeID===9){ //电能/水能
          this.getEnergyData(siteId);
      } else  if(siteServiceTypeID===1){                  //门禁
          this.getStatisticalData(siteId);
      } else  if(siteServiceTypeID===2 || siteServiceTypeID===7 || siteServiceTypeID===4){//通用设备 & 照明
          this.getEquipmentStatisticalData(siteId,siteServiceTypeID);
      } else  if(siteServiceTypeID===3){          // 3 多联机-空调 //4 水冷 //5 其他
          this.getAirStatisticalData(siteId,siteServiceTypeID);
      } else  if(siteServiceTypeID===6){ // 环境
          this.getEnvironmentStatisticalData(siteId,siteServiceTypeID);
      } else  if(siteServiceTypeID===10){
          this.getVideoCardData(siteId);
      } else  if(siteServiceTypeID===11){
          
      } else  if(siteServiceTypeID===12){
          
      } else  if(siteServiceTypeID===13){
          
      } else  if(siteServiceTypeID===14){ //访客
          this.getVisitorCardData(siteId);
      } else  if(siteServiceTypeID===15){
          
      } else  if(siteServiceTypeID===16){
          
      }
    },
    // 8&9 获取能源显示数据
    getEnergyData(siteId) {
      this.loading = true;
      getEnergyDailyData(siteId,this.token)
        .then((res) => {
          console.log("GetEnergyDailyData:", res);
          this.setOptionData(res.data);
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    // 6 获取环境数据
    getEnvironmentStatisticalData(siteId,siteServiceTypeId) {
      this.loading = true;
      let clientId = 0;
      getData("bivale/device/device/statisticsArray", {
        clientId,
        siteId,
        siteServiceTypeId,
      },this.token)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          this.option2.series[0].data = data;
          this.option2.series[0].roseType = this.roseType;
          this.title = this.$t("sitecard.a6");
          this.drawPie();
        })
        .catch((err) => {
          this.loading = false;
          console.log("错了", err);
        });
    },
    // 1 获取门禁数据
    getStatisticalData(siteId) {
      this.loading = true;
      let clientId = 105;
      getDoorCardData({
        clientId,
        siteId
      },this.token).then((res) => {
          this.loading = false;
          let { data } = res;
          this.option2.series[0].data = data;
          this.title = this.$t("sitecard.a3");
          this.drawPie();
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    // 2 获取通用设备数据 // 7 照明
    getEquipmentStatisticalData(siteId,siteServiceTypeId) {
        this.loading = true;
        let clientId = 0;
        getDeviceData( {
            clientId,
            siteId,   
            siteServiceTypeId 
        },this.token)
        .then((res) => {
            this.loading = false;
            let { data } = res;
            this.option2.series[0].data = data;
            this.option2.series[0].roseType = this.roseType;
            if(siteServiceTypeId===7){
                this.title = this.$t("sitecard.a7");
            }else{
                this.title = this.$t("sitecard.a6");
            }
            
            this.drawPie();
        })
        .catch((err) => {
          this.loading = false;
          console.log("错了",err);
        });
    },
    // 3 获取空调数据 4、水冷,5、其他,
    getAirStatisticalData(siteId,siteServiceTypeId) {
        let clientId = 105;
        this.loading = true;
        getDeviceStatusData({
          clientId,
          siteId,
          siteServiceTypeId,
        },this.token)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          this.option3.series = data.series;
          this.option3.series[1].label = {
            show: true,
            position: 'right',
            distance: 20,
            align: 'left',
            verticalAlign: 'middle',
            rotate: 0,
            formatter: '{name|{b}}  ',
            rich: {
              name: {color: '#626f92',fontSize:'1.2rem'}
            }
          };
          this.option3.legend = data.legend;
          this.option3.legend.left='right';
          this.option3.legend.textStyle = { fontSize: '1.4rem' }
          this.option3.yAxis  = data.yAxis;
          this.option3.yAxis[0].axisLabel =  {
                  show:false,
                  // inside: true,
                  // color: '#626f92'
                };
          this.option3.yAxis[0].inverse= true;
          console.log("getAirStatisticalData series:",  this.option3);
          this.title = '空调运行统计';//this.$t("sitecard.a4");
          this.drawBar();
        })
        .catch((err) => {
          console.log("错了", err);
          this.loading = false;
        });
    },
    // 10 获取视频监控
    getVideoCardData(siteId) {
        this.loading = true;
        getVideoCardData(siteId,this.token)
        .then((res) => {
          console.log("GetVideoCardData:", res);
          if (res.errorCode == "00") {
            // let series = new Array();
            if(res.data.videoCardData){
                this.option4.series[0].data = res.data.videoCardData;
                // this.serie2.data = res.data.videoCardData;
                // series.push(this.serie2);
            }
            // console.log("GetVideoCard series:",  this.option4.series);
            this.title = '摄像机分组统计';//this.$t("sitecard.a9");
            this.drawPieWithOption(this.option4);
          } else {

          }

          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    // 11 梯控
    // 12 消费

    // 13 出入口

    // 14 访客
    getVisitorCardData(siteId) {
        this.loading = true;
        let par = {
            dateTimeBegin: moment().startOf("month").format("YYYY-MM-DD HH:mm:ss"),
            dateTimeEnd: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
            groupbyStrs: [],        
            reportTypeStr: "2",
            siteId: siteId,
            tenantId: 0,
            visitorCompany: "",
            visitorName: "",
            visitorPhone: "",
            visitorType: "",
            personCode: "",
            personDept: "",
            personName: "",
            personPhone: "",
        };          
        console.log("getVisitorReportData par:",par);
        getVisitorReportData(par,this.token)
          .then((res) => {              
            this.loading = false;
            let { data } = res;
            if(data!=null&&data.length>0){
              for (let i = 0; i < data.length; i++) {
                data[i].key = i;
              }
              this.statisticsData(data);
            }
            console.log("getVisitorReportData res:",this.list);
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
    },
    statisticsData(data){
      if(data!=null&&data.length>0){
        let notVisitSum = 0;//待访数
        let inVisitSum = 0;//在访数
        let outVisitSum = 0;//离开数
        let finVisitSum = 0;//完成数
        let stVisitSum = 0;//滞留数
        let cnlVisitSum = 0;//取消数
        let misVisitSum = 0;//失约数
        for (let i = 0; i < data.length; i++) {
          notVisitSum+=data[i].notVisitSum;
          inVisitSum+=data[i].inVisitSum;
          outVisitSum+=data[i].outVisitSum;
          finVisitSum+=data[i].finVisitSum;
          stVisitSum+=data[i].stVisitSum;
          cnlVisitSum+=data[i].cnlVisitSum;
          misVisitSum+=data[i].misVisitSum;
        }

        let list = [];
        list.push({value:notVisitSum,name:this.$t("visitorStatisticalReport.a14")});
        list.push({value:inVisitSum,name:this.$t("visitorStatisticalReport.a15")});
        list.push({value:outVisitSum,name:this.$t("visitorStatisticalReport.a16")});
        list.push({value:finVisitSum,name:this.$t("visitorStatisticalReport.a17")});
        list.push({value:stVisitSum,name:this.$t("visitorStatisticalReport.a18")});
        list.push({value:cnlVisitSum,name:this.$t("visitorStatisticalReport.a19")});
        list.push({value:misVisitSum,name:this.$t("visitorStatisticalReport.a20")});

        this.option4.series[0].data = list;
        this.title = '当月访客统计';//this.$t("sitecard.a3");
        // this.drawPie();
        this.drawPieWithOption(this.option4);
      }
    },   
    // 15 考勤
    // 16 会议
    // 17 电梯
    // 18 边缘终端
    // 19 简单操作
    // 20 气
    // 21 油
    // 22 信息发布
    //

    setOptionData(chartData) {
      const {siteServiceTypeID}  = this.data;
      if(siteServiceTypeID===8){

        let name = "(KWh" + chartData.unit + ")";
        // this.option.yAxis.name = name;
        this.option.xAxis.data = chartData.categories;
        this.option.tooltip.formatter = "{a} <br/>{b}: {c} KWh";
        this.option.series.data = chartData.data;
        this.option.series.name = this.$t("energy.enedata348");
        this.option.color = "#18cdd2";
        this.option.legend ={data:[this.option.series.name],left:'left',textStyle: { fontSize: '1.4rem' }};
        this.title = this.$t("sitecard.a1");
      } else if(siteServiceTypeID===9){

        let name = "(m³" + chartData.unit + ")";
        // this.option.yAxis.name = name;
        this.option.xAxis.data = chartData.categories;
        this.option.tooltip.formatter = "{a} <br/>{b}: {c} m³";
        this.option.series.data = chartData.data;
        this.option.series.name = this.$t("energy.enedata377");
        this.option.color = "#00c2ff";
        this.option.legend ={data:[this.option.series.name],left:'left',textStyle: { fontSize: '1.4rem' }};
        
        this.title = this.$t("sitecard.a2");
      }
      if(this.index===1){
        this.option.series.barWidth = '20rem';
      }
      
      this.drawLine();
    },
    // 调用echarts
    drawLine() {
      let myChart = this.$echarts.init(document.getElementById(this.echartsId),'dark');
      let resizeDiv = document.getElementById(this.echartsId);
      myChart.setOption(this.option);
      let listener = () => {
        myChart.resize();
      };
      EleResize.on(resizeDiv, listener);
    },
    drawBar() {
      let myChart = this.$echarts.init(document.getElementById(this.echartsId),'dark');
      let resizeDiv = document.getElementById(this.echartsId);
      myChart.setOption(this.option3);
      let listener = () => {
        myChart.resize();
      };
      EleResize.on(resizeDiv, listener);
    },
    drawPie() {
      let myChart = this.$echarts.init(document.getElementById(this.echartsId),'dark');
      let resizeDiv = document.getElementById(this.echartsId);
      myChart.setOption(this.option2);
      let listener = () => {
        myChart.resize();
      };
      EleResize.on(resizeDiv, listener);
    },
    drawPieWithOption(option){
      let myChart = this.$echarts.init(document.getElementById(this.echartsId),'dark');
      let resizeDiv = document.getElementById(this.echartsId);
      myChart.setOption(option);
      let listener = () => {
        myChart.resize();
      };
      EleResize.on(resizeDiv, listener);
    }
  },
};
</script>

<style>
html {
    font-size: 40%!important; 
}
@media only screen and (min-width: 1240px){
    html {
        font-size: 45%!important; /* 10÷16=62.5% */
    }
}

@media only screen and (min-width: 1440px){
    html {
        font-size: 50%!important; /* 10÷16=62.5% */
    }
}
@media only screen and (min-width: 1900px){
    html {
        font-size: 62.5%!important; /* 10÷16=62.5% */
    }
}
.board-card-chart {
  width: 100%;
  height: 100%;
  background-color: rgba(10,70.,136,0.1);
  border-radius: 3px;
  cursor: pointer;
  flex-shrink: 0;
  transition: 200ms;
  position: relative;
  font-family: "Microsoft YaHei";
}
.main {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  position: relative;
}
.data-chart{
  position: relative;
  height: calc(100% - 2rem);
}
.data-title {
  text-align: right;
  color: #FFFFFF;
  font-size: 1.6rem;
}
.c-title{
  position:absolute;
  bottom:-2.5rem;
  width:100%;
  text-align: center;
  color:#16dffb;
  font-size: 1.5rem;
}
</style>