import request from '@/utils/request';
export function getBoardToken(key) {
    return request({
        url: 'bivale/token/'+key,       
        method: 'post'
    });
} 

export function getDisplayToken(key) {
    return request({
        url: 'bivale/token2/'+key,       
        method: 'post'
    });
} 

export function getSiteAndServiceList(params,token) {
    request.interceptors.request.use(
        config => {
          if (token) {
            config.headers['Authorization'] = `IBMS ${token}`
          }
          return config
        },
        error => {
          return Promise.reject(error)
        }
    );
    return request({
        url: 'bivale/custom/site/service/list',       
        method: 'post',
        data:params
    });
} 

// 获取门禁模块卡片统计数据
export function getDoorCardData(params,token) {
    request.interceptors.request.use(
        config => {
          if(token) {
            config.headers['Authorization'] = `IBMS ${token}`
          }
          return config
        },
        error => {
          return Promise.reject(error)
        }
    );
    return request({
        url: 'bivale/entrance/equipment/statisticsArray',
        method: 'get',
        params
    });
}

// 获取能源模块卡片统计数据---日度
export function getEnergyDailyData(siteId,token) {
    request.interceptors.request.use(
        config => {
          if (token) {
            config.headers['Authorization'] = `IBMS ${token}`
          }
          return config
        },
        error => {
          return Promise.reject(error)
        }
    );
    return request({
      url: `/bivale/energy/data/daily/${siteId}`,
      method: 'get',
    });
}
// 访客管理--获取已接访访客统计数据
export function getVisitorReportData(params,token) {
    request.interceptors.request.use(
        config => {
          if (token) {
            config.headers['Authorization'] = `IBMS ${token}`
          }
          return config
        },
        error => {
          return Promise.reject(error)
        }
    );
    return request({
        url: "bivale/vst/statistics/vstReport",
        method: 'post',
        data: params,
    });
}

export function getVideoCardData(siteId,token){
    request.interceptors.request.use(
        config => {
          if (token) {
            config.headers['Authorization'] = `IBMS ${token}`
          }
          return config
        },
        error => {
          return Promise.reject(error)
        }
    );
    return request({
        url: 'bivale/video/card/data?siteId='+siteId,
        method: 'get'
    });
}
// get请求
export function getData(url, params,token) {
    request.interceptors.request.use(
        config => {
          if (token) {
            config.headers['Authorization'] = `IBMS ${token}`
          }
          return config
        },
        error => {
          return Promise.reject(error)
        }
    );
    return request({
        url: url,
        method: 'get',
        params
    });
}

//获取设备模块卡片统计数据
export function getDeviceData(params,token) {
    request.interceptors.request.use(
        config => {
          if (token) {
            config.headers['Authorization'] = `IBMS ${token}`
          }
          return config
        },
        error => {
          return Promise.reject(error)
        }
    );
    return request({
        url: "bivale/device/device/statisticsArray",
        method: 'get',
        params
    });
}


// 获取设备模块卡片统计数据
export function getDeviceCardData(params,token) {
    request.interceptors.request.use(
        config => {
          if (token) {
            config.headers['Authorization'] = `IBMS ${token}`
          }
          return config
        },
        error => {
          return Promise.reject(error)
        }
    );
    return request({
        url: 'bivale/device/device/statisticsArray',
        method: 'get',
        params
    });
  }


  // 获取设备模块卡片统计数据
export function getDeviceStatusData(params,token) {
  request.interceptors.request.use(
      config => {
        if (token) {
          config.headers['Authorization'] = `IBMS ${token}`
        }
        return config
      },
      error => {
        return Promise.reject(error)
      }
  );
  return request({
      url: 'bivale/device/airConditioner/statisticsArray',
      method: 'get',
      params
  });
}
//getMsgData,getPassengerflowData
export function getMsgData(params){
    return request({
        // url: '/smartDisplay/messageget',
        url: 'http://10.98.233.3:8080/messageget',
        method: 'get',
        params
    });
}
// 获取人流 
export function getPassengerflowData(params){
  
    return request({
        // url: '/smartDisplay/passengerflowget',
        url: 'http://10.98.233.3:8080/passengerflowget',
        method: 'get',
        params
    });
}
