<template>
    <div id="board" v-loading="loading"  :element-loading-text="$t('alarm.a0')">
        <div class="board-header">
            <div class="board-title">{{title}}</div>
        </div>
        <div class="board-content">
            <div class="board-card" :style="cardStyle" v-for="(item,index) in data" :key="index">
                <div class="board-card-title" :style="titleStyle">
                    {{item.siteServiceTypeID*1==14?item.siteServiceTypeName:item.name}}
                </div>  
                <div class="board-card-content">
                    <chart :data="item" :token="token"></chart>
                </div> 
            </div>
            <!-- <div class="board-card"></div>
            <div class="board-card"></div>
            <div class="board-card"></div>
            <div class="board-card"></div>
            <div class="board-card"></div> -->
        </div>
        <a-icon class="flooter-icon" :type="type" @click="fullScreen" />
        <div class="flooter-time">{{displayTime.format('YYYY/MM/DD HH:mm:ss')}}</div>
    </div>

</template>
<script>
import chart from "./components/chart"; 
import { getBoardToken,getSiteAndServiceList } from "../../api/board";
import moment from "moment";

export default {
    data(){
        return{
            loading:false,
            key:'',
            title:'',
            model:[],
            flushTime:undefined,
            token:undefined,
            data:[],
            param:{},
            cardStyle:{margin: '1.6666%', width: '30%'},
            titleStyle:{fontSize:'2.4rem',marginTop:'-2.4rem'},
            timer:'',
            exctime:moment(),
            excute:false,
            displayTime:moment(),
            isfullScreen:false,
            type:'fullscreen',
        }
    },
    mounted(){
        console.log('key',this.$route.query.key);
        this.key = this.$route.query.key;
        // this.$nextTick( this.fullScreen());
        // this.fullScreen();
        if(!this.token){
            this.loading = true;
            getBoardToken(this.key)
            .then((res) => {
               
                let { data,errorCode,msg } = res;
                console.log(data);
                if(errorCode==='00'){

                    let {clientId,sitegrId,key,deviceId,token,model,flushTime,title} = data;
                    this.model = model;
                    this.param = {clientId,sitegrId,key,deviceId,model} ;
                    this.token = token;
                    this.title = title;
                    this.flushTime = flushTime;
                    this.getSiteAndServiceInfo();

                    if(this.flushTime && this.flushTime*1>0){
                        clearInterval(this.timer);
                        let that = this;
                        this.timer = setInterval(()=>{
                            let now = new moment();
                            let mstime = now.diff(that.exctime,'minutes');
                            console.log("mstime",mstime,"excute",that.excute);
                            // let time = now.format('mm');
                            // let seconds = now.diff(that.exctime,'seconds');
                            // let mod = parseInt(time);
                            if(!that.excute && mstime==that.flushTime*1){
                                that.exctime = now;
                                that.excute = true;
                                that.getSiteAndServiceInfo();
                            }
                            that.displayTime = now;
                        },1*1000);
                    }

                } else {
                    this.loading = false;
                    this.$message.error(msg);
                }
            })
            .catch((err) => {
                this.loading = false;
                console.log("错了",err);
            });
        }
        
        // console.log('Token',getToken())

    },
    methods:{
        fullScreen(){
            let elem = document.getElementById('board');
            if(this.isfullScreen){
                this.isfullScreen =false;
                this.type = 'fullscreen';


                document.exitFullscreen();
                if (elem.exitFullscreen) {
                    console.log('exitFullscreen');
                } else if (elem.webkitCancelFullScreen) {
                    console.log('webkitCancelFullScreen');
                    elem.webkitCancelFullScreen();
                } else if (elem.mozCancelFullscreen) {
                    console.log('mozCancelFullscreen');
                    elem.mozCancelFullscreen();
                } else if (elem.msRequestFullScreen) {
                    console.log('msExitFullScreen');
                    elem.msExitFullScreen();
                }

            } else {
                this.isfullScreen = true;
                this.type = 'fullscreen-exit';

                if (elem.requestFullscreen) {
                    console.log('requestFullscreen');
                    elem.requestFullscreen();
                } else if (elem.webkitRequestFullScreen) {
                    console.log('webkitRequestFullscreen');
                    elem.webkitRequestFullScreen();
                } else if (elem.mozRequestFullScreen) {
                    console.log('mozRequestFullscreen');
                    elem.mozRequestFullScreen();
                } else if (elem.msRequestFullScreen) {
                    console.log('msRequestFullscreen');
                    elem.msRequestFullScreen();
                }
            }
            
        },
        getSiteAndServiceInfo(){
            this.loading = true;
            this.data=[];
            getSiteAndServiceList(this.param,this.token)
            .then((res) => {
                this.loading = false;
                console.log(res);
                let { data,errorCode,msg } = res;
                console.log(data,errorCode,msg);
                if(errorCode==='00'){
                    let col = data.length/2;
                    col = Math.ceil(col);
                    let mar = 1.6666;
                    let wdh = 30;
                    if(col===1){
                        this.cardStyle.height= 'calc(100% - 7rem)';
                        if(data.length===1){
                        mar = 1.6666;
                        wdh = (100-1.6666*2);
                        } else {
                        mar = 1.6666;
                        wdh = (100-1.6666*4)/2;
                        }
                    } else if(col===2){
                        mar = 1.6666;
                        wdh = (100-1.6666*4)/2;
                    } else if(col===3){

                    } else if(col===4){
                        mar = 10/8;
                        wdh = 90/4;
                    this.titleStyle.fontSize = '2.0rem';
                    this.titleStyle.marginTop = '-2.0rem';
                    } else if(col===5){
                        mar = 10/10;
                        wdh = 90/5;
                        this.titleStyle.fontSize = '2.0rem';
                        this.titleStyle.marginTop = '-2.0rem';
                    } else if(col===6){
                        mar = 10/12;
                        wdh = 90/6;
                        this.titleStyle.fontSize = '1.8rem';
                        this.titleStyle.marginTop = '-1.8rem';
                    }
                    this.cardStyle.margin = mar+'%';
                    this.cardStyle.width  = wdh+'%';
                    this.data = data;
                }else{
                    this.$message.error(msg);
                }
                this.excute = false;
            })
            .catch((err) => {
                this.loading = false;
                this.excute = false;
                console.log("错了",err);
            });
        }
    },
    components:{
        chart,
    },
    beforeDestroy(){
        clearInterval(this.timer);
    },

}
</script>
<style scoped>
html {
        font-size: 40%!important; /* 10÷16=62.5% */
}
@media only screen and (min-width: 1240px){
    html {
        font-size: 42.5%!important; /* 10÷16=62.5% */
    }
}

@media only screen and (min-width: 1440px){
    html {
        font-size: 48.5%!important; /* 10÷16=62.5% */
    }
}
@media only screen and (min-width: 1900px){
    html {
        font-size: 62.5%!important; /* 10÷16=62.5% */
    }
}
#board{
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-image: url('../../../public/images/board/bg.png');
    background-repeat: no-repeat;
    background-attachment: fixed;
    color: #ffffff; 
    font-size:1rem;
    font-family: "Microsoft YaHei";
    position: relative;
}
.board-header{
    width: 100%;
    height: 10rem;
    overflow: hidden;
    background: url('../../../public/images/board/logotop.png') no-repeat;
    background-size:100% 100%;
}
.board-title{
    text-align: center;
    font-size:5rem;
    margin-top:1.0rem ;
}
.board-content{
    margin-top:2rem;
    width: 100%;
    height: calc(100% - 10rem);
    padding:1rem;
    text-align: center;
    
}
.board-content::after{
    clear: both;
}
.board-card{
    padding:1rem;
    margin: 1.6666%;
    width: 30%;
    height: calc(50% - 7rem);
    background: url('../../../public/images/board/456.png') no-repeat;
    background-size:100% 100%;
    float: left;

}

.board-card-title{
    font-size: 2.4rem;
    margin-top: -2.4rem;
    height: 4.4rem;
}

.board-card-content{
    font-size: 1rem;
    margin:1rem 1.5rem;
    height: calc(100% - 5rem);
}
.flooter-time{
    position: absolute;
    right: 8.5%;
    top: 1rem;
    font-size: 2.4rem;
    padding: 0.64rem 1rem;
}
.flooter-icon{
    display: none;
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 2rem;
    padding: 0.64rem 1rem;
    cursor: pointer;
}
.flooter-icon:hover{
    color:cornflowerblue;
}
</style>