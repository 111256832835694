<template>
    <div id="board2" v-loading="loading"  :element-loading-text="$t('alarm.a0')">
        <img class="logo" src="../../../public/images/board2/logo.png" />
        <img class="logo-left2" src="../../../public/images/bivalev.png" />
        <img class="logo-left1" src="../../../public/images/bivale-logo.png" />
        <div class="board2-header"></div>
        <div class="board2-content" v-if="col!==3">
            <div  class="board2-card" :style="cardStyle" v-for="(item,index) in data" :key="index">
                <div class="board2-card-title-small" :style="titleStyle">
                    {{item.siteServiceTypeID*1==14?item.siteServiceTypeName:item.name}}
                </div>  
                <div class="board2-card-content">
                    <chart :data="item" :token="token" :index="0"></chart>
                </div> 
            </div>
        </div>
        <div class="board2-content" v-else>
            <div  class="board2-card" :style="index%3==1?cardStyle2:((item.siteServiceTypeID===10 || item.siteServiceTypeID===14)?cardStyle3: cardStyle)" v-for="(item,index) in data" :key="index">
                <div :class="index%3==1?'board2-card-title-long':'board2-card-title-small'" :style="titleStyle">
                    {{item.siteServiceTypeID*1==14?item.siteServiceTypeName:item.name}}
                    <img class="title-img" :src="require('../../../public/images/board2/'+ennames[index])">
                </div>  
                <div class="board2-card-content">
                    <chart :data="item" :token="token" :index="(index%3==1?1:0)"></chart>
                </div> 
            </div>
        </div>
        <a-icon class="flooter-icon" :type="type" @click="fullScreen" />
        <div class="flooter-time">{{displayTime.format('YYYY/MM/DD HH:mm:ss')}}</div>
    </div>

</template>
<script>
import chart from "./components/chart"; 
import { getBoardToken,getSiteAndServiceList } from "../../api/board";
import moment from "moment";
const ennames=['VISITORMANAGEMENT.png','WATERENERGYMANAGEMENT.png','VIDEOSURVEILLANCE.png','WORKSHOPPOWERMANAGEMENT.png','POWERMANAGEMENTOFDISTRIBUTIONROOM.png','AIRCONDITIONINGMANAGEMENT.png'];
export default {
    props: {
        board:{
            type:Object,
            default:{}
        },
    },
    data(){
        return {
            loading:false,
            key:'',
            token:undefined,
            title:'',
            model:[],
            flushTime:undefined,
            data:[],
            param:{},
            cardStyle:{margin: '1.6666%', width: '30%'},
            cardStyle2:{margin: '1.6666%', width: '30%'},
            cardStyle3:{margin: '1.6666%', width: '30%'},
            titleStyle:{fontSize:'2.4rem',marginTop:'-2.4rem'},
            titleClass:{fontSize:'2.4rem',marginTop:'-2.4rem'},
            subtitleStyle:{fontSize:'1.2rem',marginTop:'-2.4rem'},
            timer:'',
            exctime:moment(),
            excute:false,
            displayTime:moment(),
            col:3,
            isfullScreen:false,
            type:'fullscreen',
            ennames
        }
    },
    mounted(){
        console.log('key',this.$route.query.key);
        this.key = this.$route.query.key;

        console.log('data',this.board);
        this.token = this.board.token;

        // this.$nextTick( this.fullScreen());
        // this.fullScreen();
        if(!this.token){
            this.loading = true;
            getBoardToken(this.key)
            .then((res) => {
               
                let { data,errorCode,msg } = res;
                console.log(data);
                if(errorCode==='00'){

                    let {clientId,sitegrId,key,deviceId,token,model,flushTime,title,theme} = data;
                    this.token = token;
                    let board =  {clientId,sitegrId,key,deviceId,model,token,title,flushTime,theme} ;
                    this.loadData(board);
                    

                } else {
                    this.loading = false;
                    this.$message.error(msg);
                }
            })
            .catch((err) => {
                this.loading = false;
                console.log("错了",err);
            });
        }else{
            this.loadData(this.board);
        }
        
        // console.log('Token',getToken())

    },
    methods:{
        loadData(data){
            let {clientId,sitegrId,key,deviceId,model,token,title,flushTime,theme} = data;
            this.model = model;
            this.param = {clientId,sitegrId,key,deviceId,model} ;
            this.token = token;
            this.title = title;
            this.flushTime = flushTime;
            this.getSiteAndServiceInfo();

            if(this.flushTime && this.flushTime*1>0){
                clearInterval(this.timer);
                let that = this;
                this.timer = setInterval(()=>{
                    let now = new moment();
                    let mstime = now.diff(that.exctime,'minutes');
                    console.log("mstime",mstime,"excute",that.excute);
                    // let time = now.format('mm');
                    // let seconds = now.diff(that.exctime,'seconds');
                    // let mod = parseInt(time);
                    if(!that.excute && mstime==that.flushTime*1){
                        that.exctime = now;
                        that.excute = true;
                        that.getSiteAndServiceInfo();
                    }
                    that.displayTime = now;
                },1*1000);
            }
        },
        fullScreen(){
            let elem = document.getElementById('board');
            if(this.isfullScreen){
                this.isfullScreen =false;
                this.type = 'fullscreen';


                document.exitFullscreen();
                if (elem.exitFullscreen) {
                    console.log('exitFullscreen');
                } else if (elem.webkitCancelFullScreen) {
                    console.log('webkitCancelFullScreen');
                    elem.webkitCancelFullScreen();
                } else if (elem.mozCancelFullscreen) {
                    console.log('mozCancelFullscreen');
                    elem.mozCancelFullscreen();
                } else if (elem.msRequestFullScreen) {
                    console.log('msExitFullScreen');
                    elem.msExitFullScreen();
                }

            } else {
                this.isfullScreen = true;
                this.type = 'fullscreen-exit';

                if (elem.requestFullscreen) {
                    console.log('requestFullscreen');
                    elem.requestFullscreen();
                } else if (elem.webkitRequestFullScreen) {
                    console.log('webkitRequestFullscreen');
                    elem.webkitRequestFullScreen();
                } else if (elem.mozRequestFullScreen) {
                    console.log('mozRequestFullscreen');
                    elem.mozRequestFullScreen();
                } else if (elem.msRequestFullScreen) {
                    console.log('msRequestFullscreen');
                    elem.msRequestFullScreen();
                }
            }
            
        },
        getSiteAndServiceInfo(){
            this.loading = true;
            this.data=[];
            getSiteAndServiceList(this.param,this.token)
            .then((res) => {
                this.loading = false;
                console.log(res);
                let { data,errorCode,msg } = res;
                console.log(data,errorCode,msg);
                if(errorCode==='00'){
                    let col = data.length/2;
                    col = Math.ceil(col);
                    let mar = 1.6666;
                    let wdh = 30;
                    if(col===1){
                        this.cardStyle.height= 'calc(100% - 7rem)';
                        
                        if(data.length===1){
                            mar = 1.6666;
                            wdh = (100-1.6666*2);
                        } else {
                            mar = 1.6666;
                            wdh = (100-1.6666*4)/2;
                        }
                        
                        this.cardStyle.margin = mar+'%';
                        this.cardStyle.width  = wdh+'%';
                    } else if(col===2){
                        mar = 1.6666;
                        wdh = (100-1.6666*4)/2;
                        this.cardStyle.margin = mar+'%';
                        this.cardStyle.width  = wdh+'%';
                    } else if(col===3){
                        mar = 10/10;
                        wdh = 90/4;

                        this.cardStyle.margin = '1%';
                        this.cardStyle.width  = '22%';

                        this.cardStyle2.margin = '1% 4%';
                        this.cardStyle2.width  = '44%';

                        this.cardStyle3.margin = '1%';
                        this.cardStyle3.width  = '22%';
                        this.cardStyle3.border = '1px solid #101821;';

                        this.titleStyle.fontSize = '1.6rem';
                        this.titleStyle.marginTop = '-2.0rem';
                        this.subtitleStyle.fontSize = '0.8rem';
                        this.subtitleStyle.marginTop = '-2.0rem';

                    } else if(col===4){
                        mar = 10/8;
                        wdh = 90/4;
                        this.titleStyle.fontSize = '2.0rem';
                        this.titleStyle.marginTop = '-2.0rem';
                        this.subtitleStyle.fontSize = '1.0rem';
                        this.subtitleStyle.marginTop = '-2.0rem';
                        this.cardStyle.margin = mar+'%';
                        this.cardStyle.width  = wdh+'%';
                    } else if(col===5){
                        mar = 10/10;
                        wdh = 90/5;
                        this.titleStyle.fontSize = '2.0rem';
                        this.titleStyle.marginTop = '-2.0rem';
                        this.subtitleStyle.fontSize = '1.0rem';
                        this.subtitleStyle.marginTop = '-2.0rem';
                        this.cardStyle.margin = mar+'%';
                        this.cardStyle.width  = wdh+'%';
                    } else if(col===6){
                        mar = 10/12;
                        wdh = 90/6;
                        this.titleStyle.fontSize = '1.8rem';
                        this.titleStyle.marginTop = '-1.8rem';
                        this.subtitleStyle.fontSize = '0.9rem';
                        this.subtitleStyle.marginTop = '-1.8rem';
                        this.cardStyle.margin = mar+'%';
                        this.cardStyle.width  = wdh+'%';
                    }
                   
                    this.data = data;
                }else{
                    this.$message.error(msg);
                }
                this.excute = false;
            })
            .catch((err) => {
                this.loading = false;
                this.excute = false;
                console.log("错了",err);
            });
        }
    },
    components:{
        chart,
    },
    beforeDestroy(){
        clearInterval(this.timer);
    },
}
</script>

<style>
html {
    font-size: 40%!important; 
}
@media only screen and (min-width: 1240px){
    html {
        font-size: 45%!important; /* 10÷16=62.5% */
    }
}

@media only screen and (min-width: 1440px){
    html {
        font-size: 50%!important; /* 10÷16=62.5% */
    }
}
@media only screen and (min-width: 1900px){
    html {
        font-size: 62.5%!important; /* 10÷16=62.5% */
    }
}


#board2{
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-image: url('../../../public/images/board2/bg-source.png');
    background-repeat: no-repeat;
    background-size:100% 100%;
    background-attachment: fixed;
    color: #ffffff; 
    font-family: "Microsoft YaHei";
    position: relative;
}
.board2-header{
    width: 100%;
    height: 50rem;
    overflow: hidden;
    background: url('../../../public/images/board2/title-head.png') no-repeat;
    background-attachment: fixed;
    background-size:100% 50%;
}
.board2-title{
    text-align: center;
    font-size:5rem;
    margin-top:1.0rem ;
}
.logo{
    position:absolute;
    top: 1.2%;
    right: 1.5%;
    height: 4.2rem;
}
.logo-left1{
    position:absolute;
    top: 1%;
    left: 1.5%;
    width: 3.6rem;
}
.logo-left2{
    position:absolute;
    top: 1.3%;
    left:  calc(1.5% + 5rem);
    height: 2.8rem;
}
.logo-left{
    position:absolute;
    top: 1.3%;
    left: 1.5%;
    height: 2.4rem;
}
.board2-content{
    margin-top: -40rem;
    width: calc(100% - 10rem);
    height: calc(100% - 8rem);
    padding: 1rem;
    text-align: center;
    margin-left: 6rem;
    position: relative;
    background: url('../../../public/images/board2/line-left-source.png') left top no-repeat,
                url('../../../public/images/board2/line-right-source.png') right top no-repeat;
    background-size:54% 95%,54% 95%;       

}
.board2-content::after{
    clear: both;
}
.board2-line-left{
    position: absolute;
    left: 5%;
    top: -4.5%;
    width:45%;
    height: 100%;
    background: url('../../../public/images/board2/line-left-source.png') no-repeat;
    background-size:100% 100%;    
    z-index: 800;
}
.board2-line-right{
    position: absolute;
    right: 5%;
    top: -4.5%;
    width:45%;
    height: 100%;
    background: url('../../../public/images/board2/line-right-source.png') no-repeat;
    background-size:100% 100%;
    z-index: 800;
}
.board2-card{
    padding:1rem;
    margin: 1.6666%;
    width: 30%;
    height: calc(50% - 7rem);
    /* background: url('../../../public/images/board/456.png') no-repeat; 
     background-size:100% 100%;*/
    float: left;
    z-index: 900;
}

.board2-card-title-long{
    height: 4rem;
    line-height: 4rem;
    text-align: left;
    padding-left: 1rem;
    background: url('../../../public/images/board2/title-long.png') no-repeat;
    background-size:100% 100%;
    font-family: '幼圆';
    font-weight: bold;
    color:#ffffff;

}
.board2-card-title-small{
    height: 4rem;
    line-height: 4rem;
    text-align: left;
    padding-left: 1rem;
    background: url('../../../public/images/board2/title-small.png') no-repeat;
    background-size:100% 100%;
    font-family: '幼圆';
    font-weight: bold;
    color:#ffffff;
}

.board2-card-content{
    font-size: 1rem;
    margin-top: 0.5rem;
    height: calc(100% - 4rem);    
}

.flooter-time {
    position: absolute;
    right: 46%;
    top: 6.5%;
    font-size: 1.4rem;
    padding: 0.64rem 1rem;
    color: #8da8d3;
}
.flooter-icon{
    display: none;
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 2rem;
    padding: 0.64rem 1rem;
    cursor: pointer;
}
.flooter-icon:hover{
    color:cornflowerblue;
}

.title-img{
    height: 1.4rem;
    margin-top: 0.5rem;
}
</style>