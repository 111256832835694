<template>
    <div id="kanban" v-loading="loading"  :element-loading-text="$t('alarm.a0')">
        <board2 v-if="theme==='board2'"      :board="board"></board2>
        <board  v-else-if="theme==='board'"  :board="board"></board>
        <div v-else></div>
        <!-- <a-empty v-else></a-empty> -->
    </div>
</template>
<script>
import { getBoardToken } from "../../api/board";
import board from "./board";
import board2 from "./board2";
import moment from "moment";
export default {
    data(){
        return {
            loading:false,
            key:'',
            token:undefined,
            theme:'',
            board:{clientId:0,sitegrId:0,key:'',deviceId:0,model:[],token:undefined,title:'',flushTime:'',theme:''},
        }
    },
    components: {
        board2,board
    },
    mounted(){
        console.log('key',this.$route.query.key);
        this.key = this.$route.query.key;
        if(!this.token){
            this.loading = true;
            getBoardToken(this.key)
            .then((res) => {
                let { data,errorCode,msg } = res;
                console.log(data);
                if(errorCode==='00'){
                    this.loading = false;
                    let {clientId,sitegrId,key,deviceId,token,model,flushTime,title,theme} = data;
                    this.token = token;
                    this.theme = theme;
                    this.board =  {clientId,sitegrId,key,deviceId,model,token,title,flushTime,theme} ;
                    // this.param = {clientId,sitegrId,key,deviceId,model} ;
                    // this.model = model;
                    // this.title = title;
                    // this.flushTime = flushTime;
                    // this.getSiteAndServiceInfo();

                    // if(this.flushTime && this.flushTime*1>0){
                    //     clearInterval(this.timer);
                    //     let that = this;
                    //     this.timer = setInterval(()=>{
                    //         let now = new moment();
                    //         let mstime = now.diff(that.exctime,'minutes');
                    //         console.log("mstime",mstime,"excute",that.excute);
                    //         // let time = now.format('mm');
                    //         // let seconds = now.diff(that.exctime,'seconds');
                    //         // let mod = parseInt(time);
                    //         if(!that.excute && mstime==that.flushTime*1){
                    //             that.exctime = now;
                    //             that.excute = true;
                    //             that.getSiteAndServiceInfo();
                    //         }
                    //         that.displayTime = now;
                    //     },1*1000);
                    // }

                } else {
                    this.loading = false;
                    this.$message.error(msg);
                }
            })
            .catch((err) => {
                this.loading = false;
                console.log("错了",err);
            });
        }
    },
}
</script>

<style scoped>
#kanban{
    width: 100%;
    height: 100%;
    overflow: hidden;
}
</style>